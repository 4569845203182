<template>
  <v-app>
    <v-navigation-drawer
      theme="light"
      image="./assets/background.jpg"
      right
      v-model="drawer"
      clipped
      app
    >
      <div class="navigation-content">
        <v-list>
          <v-list-item-group>
            <v-list-item
              class="l-item"
              v-for="section in sections"
              :key="section.name"
              @click="goTo(section.link)"
            >
              <v-list-item-content>
                <v-list-item-title color="primary">
                  {{ $t(section.name) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-app-bar height="80" elevate-on-scroll color="white" app class="header">
      <v-container>
        <v-row>
          <v-col cols="6" md="4" class="py-0">
            <div class="logo">
              <img src="@/assets/logo.png" />
              <!--div>
                <h1>BC-MD</h1>
                <p>{{ $t('bcmdSubtitle') }}</p>
              </div-->
            </div>
          </v-col>
          <v-col cols="3" md="2" class="language-column">
            <div class="language-selector">
              <v-btn color="primary" @click="showLanguages = !showLanguages">
                <img :src="selectedLanguage.img" />
                <v-icon>fa-angle-down</v-icon>
              </v-btn>
              <div v-if="showLanguages" class="languages-container">
                <v-btn
                  color="primary"
                  v-for="language in languages"
                  :key="language.title"
                  @click="selectLanguage(language)"
                >
                  <img :src="language.img" />
                  {{ $t(language.title) }}
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="3" md="6">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            </v-app-bar-nav-icon>
            <div class="wrapper-routes">
              <v-btn
                v-for="section in sections"
                :key="section.name"
                text
                color="primary"
                @click="goTo(section.link)"
                >{{ $t(section.name) }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="content">
      <!--img src="./assets/background.jpg" /-->
      <section id="whoWeAre">
        <v-container>
          <v-row>
            <v-col cols="12" md="10" offset-md="1">
              <h2>
                {{ $t('whoWeAre') }}
              </h2>
              <p>{{ $t('loreipsum') }}</p>
              <p>{{ $t('loreipsum') }}</p>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="targets" class="white">
        <v-container>
          <v-row>
            <v-col cols="12" md="10" offset-md="1">
              <!--a :href="`${publicPath}_pdf-prueba.pdf`" download="download">
                PDF
              </!--a-->
              <h2>{{ $t('targets') }}</h2>
              <p>{{ $t('loreipsum') }}</p>
              <div class="icon-text">
                <v-icon color="primary" dark>fa-coins</v-icon>
                <p>{{ $t('loreipsumShort') }}</p>
              </div>
              <div class="icon-text">
                <v-icon color="primary" dark>fab fa-bitcoin</v-icon>
                <p>{{ $t('loreipsumShort') }}</p>
              </div>
              <div class="icon-text">
                <v-icon color="primary" dark>fa-globe</v-icon>
                <p>{{ $t('loreipsumShort') }}</p>
              </div>
              <div class="icon-text">
                <v-icon color="primary" dark>fab fa-btc</v-icon>
                <p>{{ $t('loreipsumShort') }}</p>
              </div>
              <div class="icon-text">
                <v-icon color="primary" dark>fa-tasks</v-icon>
                <p>{{ $t('loreipsumShort') }}</p>
              </div>
              <div class="icon-text">
                <v-icon color="primary" dark>fa-sitemap</v-icon>
                <p>{{ $t('loreipsumShort') }}</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="roadmap">
        <v-container>
          <v-row>
            <v-col cols="12" md="10" offset-md="1">
              <h2>{{ $t('roadmap') }}</h2>
              <p>{{ $t('loreipsum') }}</p>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="contact" class="white">
        <v-container>
          <v-row>
            <v-col cols="12" md="10" offset-md="1">
              <h2>{{ $t('contact') }}</h2>
              <p>{{ $t('loreipsum') }}</p>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section>
        <v-container>
          <v-row>
            <v-col cols="12" md="10" offset-md="1">
              <h2>{{ $t('contactWithUs') }}</h2>
              <v-alert
                class="mt-4"
                v-if="commonError"
                outlined
                dense
                type="error"
              >
                {{ $t('errorTryAgain') }}
              </v-alert>
              <v-alert
                class="mt-4"
                v-if="successMessage"
                outlined
                dense
                type="success"
              >
                {{ $t('sentMessage') }}
              </v-alert>

              <ValidationObserver v-slot="{ handleSubmit }">
                <v-form
                  ref="form"
                  v-model="validForm"
                  @submit.prevent="handleSubmit(sendEmail)"
                >
                  <v-container class="ma-0 pa-0">
                    <v-row no-gutters>
                      <v-col cols="12" sm="6">
                        <ValidationProvider
                          :name="$t('name')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            :name="$t('name')"
                            :label="$t('name')"
                            v-model="form.name"
                            :error-messages="errors"
                            color="primary"
                            outlined
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" class="pl-sm-1">
                        <ValidationProvider
                          :name="$t('secondName')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            :name="$t('secondName')"
                            :label="$t('secondName')"
                            v-model="form.secondName"
                            :error-messages="errors"
                            color="primary"
                            outlined
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <ValidationProvider
                          :name="$t('phone')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <vue-tel-input-vuetify
                            mode="international"
                            outlined
                            :name="$t('phone')"
                            :label="$t('phone')"
                            placeholder=""
                            v-model="form.phone"
                            color="primary"
                            default-country="es"
                            :error-messages="errors"
                          ></vue-tel-input-vuetify>
                          {{ form.phone }}
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6" class="pl-sm-1">
                        <ValidationProvider
                          name="Email"
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            name="email"
                            label="Email"
                            v-model="form.email"
                            :error-messages="errors"
                            color="primary"
                            outlined
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <ValidationProvider
                          :name="$t('message')"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-textarea
                            :name="$t('message')"
                            :label="$t('message')"
                            v-model="form.message"
                            :error-messages="errors"
                            color="primary"
                            outlined
                          ></v-textarea>
                        </ValidationProvider>
                        <div class="wrapper-button">
                          <v-btn
                            type="submit"
                            :disabled="successMessage"
                            color="primary"
                            dark
                          >
                            {{ $t('send') }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </ValidationObserver>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-main>

    <v-footer color="white" padless app id="appFooter">
      <v-row align="center" justify="space-around">
        <v-col col="12" md="10" offset-md="1">
          <span>
            Made by Blockchain Mining & Development &copy;
            {{ new Date().getFullYear() }}
          </span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue';
import { send } from '@emailjs/browser';

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    validForm: false,
    form: {
      name: '',
      secondName: '',
      phone: '',
      email: '',
      message: '',
    },
    loading: false,
    commonError: false,
    successMessage: false,
    sections: [
      {
        link: '#whoWeAre',
        name: 'whoWeAre',
      },
      {
        link: '#targets',
        name: 'targets',
      },
      {
        link: '#roadmap',
        name: 'roadmap',
      },
      {
        link: '#contact',
        name: 'contact',
      },
    ],
    showLanguages: false,
    languages: [
      {
        title: 'spanish',
        img: 'https://flagcdn.com/20x15/es.png',
        code: 'es',
      },
      {
        title: 'english',
        img: 'https://flagcdn.com/20x15/us.png',
        code: 'en',
      },
    ],
    selectedLanguage: {
      title: 'spanish',
      img: 'https://flagcdn.com/20x15/es.png',
      code: 'es',
    },
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    goTo(element) {
      document.querySelector(element).scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    },
    selectLanguage(language) {
      this.$i18n.locale = language.code;
      this.showLanguages = false;
      this.selectedLanguage = language;
    },
    async sendEmail() {
      try {
        this.commonError = false;
        this.loading = true;
        const { name, secondName, phone, email, message } = this.form;
        await send(
          'service_62k146e',
          'template_nrjtskh',
          {
            from_name: `${name} ${secondName}`,
            phone_number: phone,
            email,
            message,
          },
          'iIfp4BqCcOZQGR59E'
        );
        this.successMessage = true;
      } catch (e) {
        console.error(e);
        this.commonError = true;
      } finally {
        this.loading = false;
      }
    },
  },
  components: {
    VueTelInputVuetify,
  },
};
</script>

<style lang="scss" scoped>
//$primary: #0b4800;
$primary: #0b35b1;
.header {
  color: $primary;
}

/*.title {
  font-family: 'Britannic Bold' !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 60px;
    line-height: 1;
  }
  p {
    font-size: 12px;
    line-height: 1;
    margin: 0;
  }
}*/
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  img {
    height: 64px;
    width: 100%;
    max-width: 240px;
  }
}
.padding-0 {
  padding: 12px 0 0;
}
.wrapper-logo {
  height: 54px;
  width: 100%;
  text-align: -webkit-center;
  text-align: -moz-center;
}
/*img {
  height: 64px;
  width: 64px;
}*/
a {
  text-decoration: none;
  .v-icon {
    margin-left: 4px;
  }
}
.wrapper-title,
.wrapper-routes {
  height: 54px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.wrapper-routes {
  a {
    margin-left: 8px;
  }
}

.wrapper-routes {
  .v-btn {
    text-transform: unset !important;
    font-family: unset !important;
    padding: 0 12px;
  }
}

.content {
  position: relative;
}
.content::before {
  content: '';
  background-image: url('./assets/background.jpg');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}

//$font-family: 'Open Sans';
//$font-family: 'Britannic Bold';
$font-family: 'Montserrat';

.v-application {
  [class*='text-'] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}

header {
  border-bottom: 1px solid #ff650033 !important;
}
section {
  padding: 32px 0 32px;
  color: $primary;
}
section.white {
  background-color: #ffffff;
}

.icon-text {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  i {
    padding-right: 12px;
  }
}

footer#appFooter {
  color: $primary;
  min-height: 40px;
  padding: 0 15px;
  text-align: center;
  display: grid;
  border-top: 1px solid #ff650033 !important;
  span {
    float: right;
  }
}

.v-navigation-drawer,
.v-app-bar__nav-icon {
  display: none;
}
.navigation-image {
  height: 100%;
  width: 100%;
  opacity: 0.1;
}

.navigation-content {
  position: relative;
}
.navigation-::before {
  content: '';
  background-image: url('./assets/background.jpg');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}
.language-column {
  display: flex;
  align-items: center;
  justify-content: center;
}
.language-selector {
  position: relative;
  img {
    height: 15px;
    width: 20px;
    margin-right: 4px;
  }
  .languages-container {
    position: absolute;
    top: 37px;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 960px) {
  .title {
    h1 {
      font-size: 2.5em;
    }
    p {
      font-size: 0.5em;
    }
  }

  img {
    height: 54px;
    width: 54px;
  }
  .v-navigation-drawer,
  .v-app-bar__nav-icon {
    display: block;
    float: right;
    margin-top: 3px;
  }

  .wrapper-routes {
    display: none;
  }
}
@media (max-width: 600px) {
  .title {
    h1 {
      font-size: 1.5em;
    }
    p {
      font-size: 0.3em;
    }
  }

  img {
    height: 48px;
    width: 48px;
  }

  a span {
    display: none;
  }

  footer#appFooter {
    justify-content: center;
    text-align: center;
    span {
      display: inline-block;
      float: none;
    }
  }
}
</style>
