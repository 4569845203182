import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0b35b1',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFEE58',
        indigoLighten2: '#7986CB',
        indigoLighten1: '#5C6BC0',
        indigoDarken1: '#3949AB',
        indigoDarken2: '#303F9F',
        indigoDarken3: '#283593',
        indigoDarken4: '#1A237E',
        lightBlueLighten2: '#4FC3F7',
        lightBlueLighten1: '#29B6F6',
        lightBlueDarken1: '#039BE5',
        lightBlueDarken2: '#0288D1',
        lightBlueDarken3: '#0277BD',
        lightBlueDarken4: '#01579B',
        yellowLighten5: '#FFFDE7',
        yellowLighten4: '#FFF9C4',
        yellowLighten3: '#FFF59D',
        yellowLighten2: '#FFF176',
        yellowLighten1: '#FFEE58',
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'fa',
  },
});
